/* Other colors
    primary: '#0A67A3',
    secondary: '#424242',
    accent: '#FF8E00',

    //primary: '#1E88E5',
    //secondary: '#1565C0',
    //accent: '#F57F17',
    
    // secondary: '#3E97D1',
    
    // error: '#FF5252',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FFC107',
*/
/* Default Vuetify colors
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
    */

module.exports = {
  primary: "#0A67A3",
  secondary: "#424242",
  accent: "#FF8E00",
  info: "#1890ff",
  success: "#5cb860",
  warning: "#ffa21a",
  error: "#f55a4e"

  /*success: '#52c41a',
    warning: '#faad14',
    error: '#f5222d'*/
};
